<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadPlantContracts(true)" />
                </div>
            </div>
            <el-table :data="plantContracts.data" class="mt-2">
                <el-table-column prop="year" label="年份" sortable width="80" />
                <el-table-column label="名称/编号">
                    <template #default="scope">
                        <div>
                            <router-link :to="'/plant-contract/detail?id='+scope.row.id" target="_blank">
                                {{scope.row.name}}
                            </router-link>
                        </div>
                        <div class="text-info">{{scope.row.code}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="farmerName" label="种植户" sortable width="160" />
                <el-table-column prop="warrantorNames" label="担保人" sortable width="160" />
                <el-table-column prop="totalArea" label="总面积（亩）" sortable width="160" />
                <el-table-column label="承包日期" width="140">
                    <template #default="scope">
                        <div>{{$moment.ld(scope.row.beginContractDate)}}</div>
                        <div>{{$moment.ld(scope.row.endContractDate)}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="70">
                    <template #default="scope">
                        <el-button type="text" @click="showAuditPlantContractDialog(scope.row)">审核</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="plantContracts.totalRecords"
                :current-page="plantContractsPagination.currentPage" :page-size="plantContractsPagination.pageSize"
                class="mt-3" @current-change="plantContractsPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="auditPlantContractDialogVisible" title="审核" :close-on-click-modal="false"
            width="960px" top="0px">
            <iframe v-if="auditPlantContractDialogVisible" :src="'/#/plant-contract/detail?id='+plantContract.id"
                frameborder="0" class="d-block border border-primary rounded w-100"
                style="height: calc(100vh - 280px);"></iframe>

            <el-form class="mt-2">
                <el-form-item label="审核备注">
                    <el-input v-model="auditRemark" placeholder="审核备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="success" @click="auditPlantContract(true)">审核通过</el-button>
                <el-button type="danger" @click="auditPlantContract(false)">审核退回</el-button>
                <el-button @click="auditPlantContractDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                plantContracts: {},
                plantContractsPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                plantContract: {},
                auditRemark: null,
                auditPlantContractDialogVisible: false,
            };
        },
        methods: {
            async loadPlantContracts(resetPage = false) {
                if (resetPage) {
                    this.plantContractsPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/PlantContract/GetPlantContracts', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 2,
                        key: this.key,
                        pageIndex: this.plantContractsPagination.currentPage - 1,
                        pageSize: this.plantContractsPagination.pageSize,
                    }
                });
                this.plantContracts = response.data;
            },
            plantContractsPaginationCurrentChange(page) {
                this.plantContractsPagination.currentPage = page;
                this.loadPlantContracts();
            },
            showAuditPlantContractDialog(plantContract) {
                this.plantContract = JSON.parse(JSON.stringify(plantContract));
                this.auditRemark = null;
                this.auditPlantContractDialogVisible = true;
            },
            async auditPlantContract(isPass) {
                if (!confirm(`确定要审核${isPass ? '通过' : '退回'}吗？`)) {
                    return;
                }

                await this.$axios.post('/api/PlantContract/AuditPlantContract', {
                    id: this.plantContract.id,
                    isPass: isPass,
                    auditRemark: this.auditRemark,
                });
                this.auditPlantContractDialogVisible = false;
                this.loadPlantContracts();
            },
        },
        created() {
            this.loadPlantContracts();
        },
    };
</script>